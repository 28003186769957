import { Link } from "react-router-dom";
const EventItem = ({ listing }) => {
  return (
    <article className="bg-white shadow-md hover:shadow-lg transition-shadow overflow-hidden rounded-lg w-full sm:w-[330px] m-2">
      <Link
        to={`/event/${listing._id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={
            //"https://whatson.melbourne.vic.gov.au/rails/active_storage/representations/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWs0TWpObFpHUTJPQzA0WW1JMUxUUTFaak10T1RBd055MHlZbU5qT0RneU16Y3daV01HT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--e5911f49fced9eb34732bb63af05c26d5b84dc8d/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdDRG9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2REdkeVlYWnBkSGxKSWd0RFpXNTBaWElHT3daVU9oTnlaWE5wZW1WZmRHOWZabWxzYkZzSGFRSndBMmtDVGdJPSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--f3876e1fcc2db92cdffea604834734701decf454/512e3363-c342-41fa-a003-ebaf771589c9.jpg"
            listing.imageUrls[0] ||
            "https://53.fs1.hubspotusercontent-na1.net/hub/53/hubfs/Sales_Blog/real-estate-business-compressor.jpg?width=595&height=400&name=real-estate-business-compressor.jpg"
          }
          alt="listing cover"
          className="h-[320px] sm:h-[220px] w-full object-cover hover:scale-105 transition-scale duration-300"
          loading="lazy"
        />
        <div className="p-3 flex flex-col gap-2 w-full">
          <p className="truncate text-lg font-semibold text-slate-700">
            {listing.title}
            {/* Chinese Lunar New Year Dragon Festival */}
          </p>
          <p className="text-sm text-gray-600 line-clamp-2">
            {listing.description}
            {/* Welcome the Year of the Dragon at Melbourne's Lunar New Year festival.
          Head to Chinatown for this day-long celebration, catch live
          performances on the main stage and look out for roaming lion dancers.
          Browse the market stores around Russell Street. Then explore more of
          the best Lunar New Year events. */}
          </p>
          <p className="text-slate-500 mt-2 font-semibold ">
            {/* $
            {listing.offer
              ? listing.discountPrice.toLocaleString("en-US")
              : listing.regularPrice.toLocaleString("en-US")}
            {listing.type === "rent" && " / month"} */}
            {listing.eventDtFrom &&
              listing.eventDtFrom !== "1970-01-01T00:00:00.000Z" &&
              new Date(listing.eventDtFrom).toDateString() !==
                new Date(listing.eventDtTo).toDateString() && (
                <span>{new Date(listing.eventDtFrom).toDateString()} - </span>
              )}
            {listing.eventDtTo &&
              listing.eventDtTo !== "1970-01-01T00:00:00.000Z" &&
              new Date(listing.eventDtTo).toDateString()}
          </p>
        </div>
      </Link>
    </article>
  );
};

export default EventItem;
