import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { useSelector } from "react-redux";
import { Navigation } from "swiper/modules";
import "swiper/css/bundle";
import { FaMapMarkerAlt, FaShare, FaCalendar } from "react-icons/fa";
import Contact from "../components/Contact";

import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

export default function EventListing() {
  SwiperCore.use([Navigation]);
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [copied, setCopied] = useState(false);
  // const [contact, setContact] = useState(false);
  const [contact, setContact] = useState(true);
  const params = useParams();
  const { currentUser } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        setLoading(true);
        const res = await fetch(`/api/event/get/${params.eventId}`);
        const data = await res.json();
        if (data.success === false) {
          setError(true);
          setLoading(false);
          return;
        }
        setListing(data);
        setLoading(false);
        setError(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    fetchListing();
  }, [params.eventId]);

  return (
    <main>
      {loading && <p className="text-center my-7 text-2xl">Loading...</p>}
      {error && (
        <p className="text-center my-7 text-2xl">Something went wrong!</p>
      )}
      {listing && !loading && !error && (
        <div className="lg:mx-72">
          {/* Breadcrumb */}
          <nav
            className="flex px-5 py-3 text-gray-700 rounded-lg bg-gray-250 dark:bg-gray-800 dark:border-gray-700"
            aria-label="Breadcrumb"
          >
            <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
              <li className="inline-flex items-center">
                <a
                  href="/"
                  className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  Home
                </a>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <a
                    href={"/event/" + listing._id}
                    className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    <svg
                      className="rtl:rotate-180  w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                      Event
                    </span>
                  </a>
                </div>
              </li>
            </ol>
          </nav>
          {/* End of Breadcrumb */}
          <Swiper navigation>
            {/* Test adding index */}
            {listing.imageUrls.map((url, idx) => (
              // <SwiperSlide key={url}>
              <SwiperSlide key={idx}>
                <div
                  className="h-[250px] sm:h-[450px] md:h-[550px]"
                  style={{
                    background: `url("${url}") center no-repeat`,
                    backgroundSize: "cover",
                  }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="fixed top-[18%] right-[3%] lg:right-[21%] z-10 border rounded-full w-12 h-12 flex justify-center items-center bg-slate-100 cursor-pointer">
            <FaShare
              className="text-slate-500"
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}
            />
          </div>
          {copied && (
            <span className="fixed top-[23%] right-[5%] z-10 rounded-md bg-slate-100 p-2">
              Link copied!
            </span>
          )}
          <div className="flex flex-col max-w-4xl mx-auto p-3 my-7 gap-4">
            <span className="text-2xl font-semibold">{listing.title}</span>
            <span className="flex items-center mt-6 gap-2 text-slate-600  text-sm">
              <a
                className="hover:font-medium"
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://www.google.com/maps?f=q&hl=en&ie=UTF8&oe=UTF8&geocode&q=" +
                  listing.address
                    .replace(",", "")
                    .replace(/[\u202F\u00A0]/, "")
                    .split(" ")
                    .join("+")
                }
              >
                <FaMapMarkerAlt
                  size={"1.5em"}
                  className="text-green-700 inline-block"
                />
                {listing.address}
              </a>
            </span>

            <span className="text-slate-800">
              <span className="font-semibold text-black">Description - </span>
              {listing.description}
            </span>

            {listing.eventDtFrom && (
              <span className="text-slate-800">
                <span className="font-semibold text-black-100">
                  {/* <FaCalendar className="text-lime-500 inline-block" /> Date :{" "} */}
                </span>
                <div className="flex flex-col lg:flex-row lg:w-3/5 font-semibold">
                  {listing.eventDtFrom &&
                    listing.eventDtFrom !== "1970-01-01T00:00:00.000Z" && (
                      <div className="">
                        From
                        <br />
                        <DateTimePicker
                          value={listing.eventDtFrom}
                          disabled
                          clearIcon
                        />
                      </div>
                    )}
                  {listing.eventDtTo &&
                    listing.eventDtTo !== "1970-01-01T00:00:00.000Z" && (
                      <div className={`${listing.eventDtFrom && "lg:ml-14"}`}>
                        {listing.eventDtFrom ? "To" : "Until"}
                        <br />
                        <DateTimePicker
                          value={listing.eventDtTo}
                          disabled
                          clearIcon
                        />
                      </div>
                    )}

                  {listing.evnttype === "ad" && contact && (
                    <Contact listing={listing} />
                  )}
                </div>
              </span>
            )}
          </div>
        </div>
      )}
    </main>
  );
}
