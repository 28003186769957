import { FaSearch, FaUserCircle, FaSignInAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; //, useLocation
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import NavRoutes from "../NavRoutes";

const Header = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  // let location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("searchTerm", searchTerm);
    const searchQuery = urlParams.toString();
    navigate(`/search?${searchQuery}`);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchTermFromUrl = urlParams.get("searchTerm");
    if (searchTermFromUrl) {
      setSearchTerm(searchTermFromUrl);
    }
  }, [window.location.search]);

  return (
    <header className="bg-slate-200 shadow-md">
      <h1 className="hidden">
        Shares pefect places and things at Docklands and Melbourne
      </h1>
      <div className="flex justify-between items-center max-w-6xl mx-auto p-3">
        <Link to="/">
          <div className="font-bold text-sm sm:text-xl flex flex-wrap">
            <span className="font-righteous text-3xl text-red-600">
              migoloo
            </span>
            {/* <span className="text-green-600">|</span>
            <span className="text-green-500">|</span>
            <span className="text-green-400">|</span> */}
            {/* <span className="text-slate-500">Shared</span> */}
          </div>
        </Link>
        <form
          onSubmit={handleSubmit}
          className="bg-slate-100 p-3 rounded-lg flex items-center"
        >
          <input
            type="text"
            placeholder="Search..."
            className="bg-transparent focus:outline-none w-24 sm:w-64"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button>
            <FaSearch className="text-slate-600" />
          </button>
        </form>
        <ul className="lg:flex gap-4 hidden">
          {/* <Link to="/">
            <li className="hidden sm:inline text-slate-700 font-semibold hover:font-bold hover:opacity-65">
              Home
            </li>
          </Link>
          <Link to="/about">
            <li className="hidden sm:inline text-slate-700 font-semibold hover:font-bold hover:opacity-65">
              About
            </li>
          </Link>
          <Link to="/profile">
            {currentUser ? (
              <img
                className="rounded-full h-7 w-7 object-cover"
                src={currentUser.avatar}
                alt="profile"
              />
            ) : (
              <li className=" text-slate-700 hover:underline"> Sign in</li>
            )}
          </Link> */}

          {NavRoutes.map((route, idx) => {
            const { Icon, href, title } = route;
            return (
              <span key={idx}>
                <Link to={href}>
                  <li className="hidden sm:inline text-slate-700 font-semibold hover:font-bold hover:opacity-65">
                    {title}
                  </li>
                </Link>
              </span>
            );
          })}
          <Link to="/profile">
            {currentUser ? (
              <img
                className="rounded-full h-7 w-7 object-cover"
                src={currentUser.avatar}
                alt="profile"
              />
            ) : (
              <li className=" text-slate-700 font-semibold hover:font-bold hover:opacity-65">
                {" "}
                Sign in
              </li>
            )}
          </Link>
        </ul>
        {/* Mobile nav */}
        <section className="flex lg:hidden gap-4">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              {/* <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/about">About</a>
              </li> */}
              <span className="font-righteous text-3xl text-red-600 underline">
                migoloo
              </span>
              {NavRoutes.map((route, idx) => {
                const { Icon, href, title } = route;
                return (
                  <span key={idx}>
                    <Link
                      to={href}
                      onClick={() => {
                        setIsNavOpen(false);
                      }}
                    >
                      <li className="border-b border-gray-400 my-8 uppercase font-semibold hover:font-bold hover:opacity-65 inline-flex">
                        <Icon size={"1.5em"} />
                        &nbsp;
                        {title}
                      </li>
                    </Link>
                  </span>
                );
              })}
              <Link
                to="/profile"
                onClick={() => {
                  setIsNavOpen(false);
                }}
              >
                {currentUser ? (
                  <li className="border-b border-gray-400 my-8 uppercase font-semibold hover:font-bold hover:opacity-65 inline-flex">
                    <FaUserCircle size={"1.5em"} />
                    &nbsp; Profile
                  </li>
                ) : (
                  <li className=" border-b border-gray-400 my-8 uppercase font-semibold hover:font-bold hover:opacity-65 inline-flex">
                    <FaSignInAlt size={"1.5em"} />
                    &nbsp;Sign in
                  </li>
                )}
              </Link>
            </ul>
          </div>
        </section>
        {/* End of Mobile nav */}
      </div>
    </header>
  );
};

export default Header;
