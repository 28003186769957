import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaMailBulk } from "react-icons/fa";

export default function Contact({ listing }) {
  const [landlord, setLandlord] = useState(null);
  const [message, setMessage] = useState("");
  const onChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    const fetchLandlord = async () => {
      try {
        const res = await fetch(`/api/user/contact/${listing.userRef}`);
        const data = await res.json();
        setLandlord(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLandlord();
  }, [listing.userRef]);
  return (
    <>
      {landlord && (
        <div className="flex flex-col gap-2 pt-3">
          <span>
            {/* Contact <span className="font-semibold">{landlord.username}</span>{" "}
            for{" "}
            <span className="font-semibold">{listing.name.toLowerCase()}</span> */}

            <div className="flex items-center">
              <img
                className="w-10 h-10 rounded-full mr-4"
                src={
                  landlord.avatar
                    ? landlord.avatar
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
                alt="Avatar of Writer"
              />
              <div className="text-sm">
                <span className="text-gray-900 leading-none font-semibold">
                  {listing.agentName ? listing.agentName : landlord.username}
                </span>
                {/* <span className="">
                  {landlord.usercompany && (
                    <> */}
                {/* <span className="font-bold text-gray-700">Company: </span> */}
                <div className="font-bold text-gray-500 text-lg">
                  {!listing.dataSource
                    ? landlord.usercompany
                    : listing.company
                    ? listing.company
                    : ""}
                </div>
                {/* </>
                  )}
                </span> */}
                {!listing.dataSource && (
                  <div>
                    <span>
                      <FaMailBulk
                        size={"1.2em"}
                        className="inline-block text-gray-500"
                      />{" "}
                      {landlord.email}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </span>
          {/* <textarea
            name="message"
            id="message"
            rows="2"
            value={message}
            onChange={onChange}
            placeholder="Enter your message here..."
            className="w-full border p-3 rounded-lg"
          ></textarea>

          <Link
            to={`mailto:${landlord.email}?subject=Regarding ${listing.name}&body=${message}`}
            className="bg-slate-700 text-white text-center p-3 uppercase rounded-lg hover:opacity-95"
          >
            Send Message
          </Link> */}
        </div>
      )}
    </>
  );
}
