import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import "swiper/css/bundle";
import ListingItem from "../components/ListingItem";
import SmallEventItem from "../components/SmallEventItem";
import EventItem from "../components/EventItem";
// import { usePWAInstall } from "react-use-pwa-install";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";

export default function Home() {
  const allowedDomain = ["https://migoloo.webhop.me", "http://localhost:3001"];
  const domainOrigin = allowedDomain.find(
    (element) => element === window.location.origin
  );
  // console.log(domainOrigin);

  // const install = usePWAInstall();
  const [offerListings, setOfferListings] = useState([]);
  const [saleListings, setSaleListings] = useState([]);
  const [rentListings, setRentListings] = useState([]);
  SwiperCore.use([Navigation]);
  console.log(offerListings);
  useEffect(() => {
    const fetchOfferListings = async () => {
      try {
        const res = await fetch("/api/listing/get?offer=true&limit=4");
        const data = await res.json();
        setOfferListings(data);
        fetchRentListings();
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRentListings = async () => {
      try {
        const res = await fetch("/api/listing/get?type=rent&limit=4");
        const data = await res.json();
        setRentListings(data);
        fetchSaleListings();
      } catch (error) {
        console.log(error);
      }
    };

    const fetchSaleListings = async () => {
      try {
        const res = await fetch("/api/listing/get?type=sale&limit=4");
        const data = await res.json();
        setSaleListings(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchOfferListings();
  }, []);

  // fetchEvents
  const [eventListingsTop, setEventListingsTop] = useState([]);
  const [eventListings1, setEventListings1] = useState([]);
  const [eventListings2, setEventListings2] = useState([]);
  const [adListings1, setAdListings1] = useState([]);
  const [adListings2, setAdListings2] = useState([]);
  useEffect(() => {
    const fetchEventListings1 = async () => {
      try {
        const res = await fetch(
          "/api/event/get?type=evnt&order=asc&startIndex=1&limit=2"
        );
        const data = await res.json();
        setEventListings1(data);
        fetchAdtListings1();
      } catch (error) {
        console.log(error);
      }
    };
    const fetchAdtListings1 = async () => {
      try {
        const res = await fetch(
          "/api/event/get?type=ad&sort=updatedAt&order=asc&limit=1"
        );
        const data = await res.json();
        setAdListings1(data);
        fetchEventListings2();
      } catch (error) {
        console.log(error);
      }
    };
    const fetchEventListings2 = async () => {
      try {
        const res = await fetch(
          "/api/event/get?type=evnt&order=asc&startIndex=3&limit=2"
        );
        const data = await res.json();
        setEventListings2(data);
        fetchAdtListings2();
      } catch (error) {
        console.log(error);
      }
    };
    const fetchAdtListings2 = async () => {
      try {
        const res = await fetch(
          "/api/event/get?type=ad&sort=updatedAt&order=asc&startIndex=1&limit=2"
        );
        const data = await res.json();
        setAdListings2(data);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchEventListingsTop = async () => {
      try {
        const res = await fetch("/api/event/get?type=evnt&order=asc&limit=1");
        const data = await res.json();
        setEventListingsTop(data);
        // if (domainOrigin)
        fetchEventListings1();
      } catch (error) {
        console.log(error);
      }
    };

    fetchEventListingsTop();
  }, []);
  // End of fetchEvents

  return (
    <div>
      <AddToHomeScreen />
      {/* {install && (
        <button
          type="button"
          class="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-b-2xl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium text-sm px-5 py-1 text-center me-2 mb-2 ms-2"
          onClick={install}
        >
          Install Me
        </button>
      )} */}
      <div className="flex flex-col lg:flex-row px-3 max-w-full mx-auto">
        {/* <div class="flex-auto w-full sm:w-1/6">AAAA</div> */}
        <div className="flex-auto w-full">
          {/* top */}
          {/* p-28  */}
          {/* <div
            className={`flex flex-col md:flex-row ${
              domainOrigin !== null ? "px-3 max-w-6xl mx-auto" : ""
            }`}
          > */}
          <div
            className={`flex flex-col md:flex-row ${
              domainOrigin !== null ? "px-3 max-w-6xl mx-auto" : ""
            }`}
          >
            <div className="flex flex-col gap-6 px-3 max-w-6xl">
              <h2 className="text-slate-700 font-bold text-3xl lg:text-6xl">
                Share <span className="text-slate-500">perfect</span>
                <br />
                places and things
              </h2>
              {/* <div className="text-gray-400 text-xs sm:text-sm"> */}
              <div className="text-gray-500 text-md md:text-lg">
                {/* <span className=" font-righteous text-xl font-bold text-red-400">
                migoloo
              </span>{" "} */}
                {/* <span className="text-slate-400 text-xl font-bold">Shared</span>{" "} */}
                {/* is the best place to share perfect places to live.
              <br />
              We have a wide range of properties for you to choose from. */}
                about Docklands and Melbourne
              </div>
              <Link
                to={"/search"}
                className="text-xs sm:text-sm text-blue-800 font-bold hover:underline"
              >
                Let's get started...
              </Link>
            </div>
            <div className="text-center pl-3 pt-4">
              {/* {eventListings && <SmallEventItem listing={eventListings[0]} />} */}
              {eventListingsTop.map((listing) => (
                <SmallEventItem listing={listing} key={listing._id} />
              ))}
            </div>
          </div>

          {/* swiper */}
          {/* <div className="lg:mx-72"> */}
          {/* <div>
            <Swiper navigation>
              {offerListings &&
                offerListings.length > 0 &&
                offerListings.map((listing, idx) => (
                  <SwiperSlide key={idx}>
                    <div
                      style={{
                        background: `url(${listing.imageUrls[0]}) center no-repeat`,
                        backgroundSize: "cover",
                      }}
                      className="h-[500px]"
                      key={listing._id}
                    ></div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div> */}

          {/* listing results for offer, sale and rent */}

          {/* Show Loading if data is not ready */}
          {
            // offerListings.length === 0 ||
            (rentListings.length <= 0 || saleListings.length <= 0) && (
              <div className="text-center px-3 max-w-6xl mx-auto pt-10">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="inline w-8 h-8 text-gray-300 animate-spin dark:text-gray-600 fill-slate-700"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                  <h2 className="text-center text-slate-700 text-xl font-semibold">
                    <div className="text-sm">
                      {rentListings && rentListings.length <= 0 && (
                        <span>Rent Listing, Loading...</span>
                      )}
                      {saleListings &&
                        saleListings.length <= 0 &&
                        rentListings.length > 0 && (
                          <span>Sale Listing, Loading...</span>
                        )}
                      {/* Loading... */}
                    </div>
                  </h2>
                  <p className="text-center text-slate-700">
                    This may take a few seconds.
                  </p>
                </div>
              </div>
            )
          }
          {/* End of Show Loading if data is not ready */}

          <div className="max-w-6xl mx-auto p-3 flex flex-col gap-8 my-10">
            {offerListings && offerListings.length > 0 && (
              <section className="">
                <div className="my-3">
                  <span className="text-2xl font-semibold text-slate-600">
                    Recent offers
                  </span>
                  <Link
                    className="text-sm text-blue-800 hover:underline"
                    to={"/search?offer=true"}
                  >
                    Show more offers
                  </Link>
                </div>
                <div className="flex flex-wrap gap-4">
                  {offerListings.map((listing) => (
                    <ListingItem listing={listing} key={listing._id} />
                  ))}
                </div>
              </section>
            )}

            {rentListings && rentListings.length > 0 && (
              <section className="">
                <div className="my-3">
                  <h2 className="text-2xl font-semibold text-slate-600">
                    Recent places for rent
                  </h2>
                  <Link
                    className="text-sm text-blue-800 hover:underline"
                    to={"/search?type=rent"}
                  >
                    Show more places for rent
                  </Link>
                </div>
                <div className="flex flex-wrap gap-4">
                  {rentListings.map((listing) => (
                    <ListingItem listing={listing} key={listing._id} />
                  ))}
                </div>
              </section>
            )}
            {saleListings && saleListings.length > 0 && (
              <section className="">
                <div className="my-3">
                  <h2 className="text-2xl font-semibold text-slate-600">
                    Recent places for sale
                  </h2>
                  <Link
                    className="text-sm text-blue-800 hover:underline"
                    to={"/search?type=sale"}
                  >
                    Show more places for sale
                  </Link>
                </div>
                <div className="flex flex-wrap gap-4">
                  {saleListings.map((listing) => (
                    <ListingItem listing={listing} key={listing._id} />
                  ))}
                </div>
              </section>
            )}
          </div>
        </div>
        {/* {domainOrigin && ( */}
        <div className="flex-auto mx-auto lg:w-3/12 w-full gap-8 my-10 p-3">
          <section className="">
            <h2 className="text-2xl font-semibold text-slate-700">
              Things to share...
            </h2>
            <div className="flex md:flex-row lg:flex-col flex-col">
              {eventListings1.map((listing) => (
                <EventItem listing={listing} key={listing._id} />
              ))}
            </div>
            {domainOrigin && (
              <div className="flex md:flex-row lg:flex-col flex-col">
                {adListings1.map((listing) => (
                  <EventItem listing={listing} key={listing._id} />
                ))}
              </div>
            )}
            <div className="flex md:flex-row lg:flex-col flex-col">
              {eventListings2.map((listing) => (
                <EventItem listing={listing} key={listing._id} />
              ))}
              {domainOrigin && (
                <div className="flex md:flex-row lg:flex-col flex-col">
                  {adListings2.map((listing) => (
                    <EventItem listing={listing} key={listing._id} />
                  ))}
                </div>
              )}
            </div>
          </section>
        </div>
        {/* )}  */}
      </div>
    </div>
  );
}
