import React from "react";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="">
      <hr className="my-6 border-blueGray-300 border-1" />
      <div className="xs:flex-col sm:flex justify-between">
        {/* w-64 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 */}
        {/* <div className="flex flex-wrap items-center md:justify-between justify-center"> */}
        <div className="">
          <div className="w-full px-4">
            <div className="text-sm text-blueGray-500 font-semibold inline-flex">
              <span id="get-current-year" className="">
                Copyright&nbsp;&copy;&nbsp;{new Date().getFullYear()}
                <span className="font-righteous text-red-600 text-lg inline-flex">
                  &nbsp;migoloo
                </span>
              </span>
            </div>
          </div>
        </div>

        <Link to="mailto:migoloo@outlook.com" className="px-4 mb-2">
          <button
            className="b-2 bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
            type="button"
          >
            <FaMailBulk className="text-slate-500 hover:size-5 inline-block" />
          </button>
          <span className="text-slate-400 font-semibold hover:text-lg">
            Email us
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
