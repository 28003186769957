import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaEnvelope,
  FaUserCheck,
  FaAngleDoubleRight,
  FaUser,
} from "react-icons/fa";

const EmailConfirm = () => {
  const navigate = useNavigate();
  const [userCheck, setUserCheck] = useState(false);
  useEffect(() => {
    const fetchEmailConfirm = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get("ecd");
        const code = queryParams.get("code");
        // const res =
        await fetch(`/api/auth/emailconfirm/${id}/${code}`);
        // const data = await res.json();
        // console.log("data", data);
        setUserCheck(true);

        const timeout = setTimeout(function () {
          navigate("/sign-in");
        }, 2000);

        return function () {
          clearTimeout(timeout);
        };
      } catch (error) {
        console.log(error);
      }
    };

    const verifiyTimeout = setTimeout(function () {
      fetchEmailConfirm();
    }, 2000);
    return function () {
      clearTimeout(verifiyTimeout);
    };
  });

  return (
    <div className="bg-white shadow-md hover:shadow-lg transition-shadow overflow-hidden rounded-lg max-w-lg max-h-72 mx-auto mt-20">
      <h2 className="text-3xl font-bold flex justify-center pt-14 text-slate-600">
        {userCheck ? "Verified" : "Verifying your email..."}
      </h2>
      <div class="h-screen flex justify-center pt-5">
        <div class="animate-pulse">
          <div class="flex items-center justify-center mt-4">
            <FaEnvelope className="text-slate-500" size={70} />
            <div class="w-20 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 me-3 ml-2"></div>
            <FaAngleDoubleRight className="text-slate-400" size={50} />
            <div class="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>

            {userCheck ? (
              <FaUserCheck className="text-slate-500" size={70} />
            ) : (
              <FaUser className="text-slate-500" size={70} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirm;
