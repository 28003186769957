import { FaHome,  FaBookmark } from "react-icons/fa";

export const NavRoutes = [
    {
      title: "Home",
      href: "/",
      Icon: FaHome,
    },
    // {
    //     title: "About",
    //     href: "/about",
    //     Icon: FaBookmark,
    // },
    
  ];
export default NavRoutes
