import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import About from './pages/About';
import Profile from './pages/Profile';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import CreateListing from './pages/CreateListing';
import UpdateListing from './pages/UpdateListing';
import Listing from './pages/Listing';
import Search from './pages/Search';
import EmailConfirm from './pages/EmailConfirm';
import ForgetPassword from './pages/ForgetPassword';
import Footer from './components/Footer';
import CreateEvent from './pages/CreateEvent';
import EventListing from './pages/EventListing';
import UpdateEventListing from './pages/UpdateEventListing';
import withSplashScreen from './components/withSplashScreen';

// import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home title="migoloo | Home" />} />
        <Route path='/sign-in' element={<SignIn title="migoloo | SignIn" />} />
        <Route path='/sign-up' element={<SignUp title="migoloo | SignUp" />} />
        <Route path='/about' element={<About title="migoloo | About"/>} />
        <Route path='/search' element={<Search title="migoloo | Search" />} />
        <Route path='/listing/:listingId' element={<Listing title="migoloo | Listing" />} />
        <Route path='/event/:eventId' element={<EventListing title="migoloo | Event" />} />

        <Route path='/emailconfirm' element={<EmailConfirm title="migoloo | Email Confirm" />} />
        <Route path='/forgetpwd' element={<ForgetPassword title="migoloo | Forget Password" />} />

        <Route element={<PrivateRoute />}>
          <Route path='/profile' element={<Profile title="migoloo | Profile" />} />
          <Route path='/create-listing' element={<CreateListing title="migoloo | Create Listing" />} />
          <Route path='/create-event' element={<CreateEvent title="migoloo | Create Event" />} />
          <Route
            path='/update-listing/:listingId'
            element={<UpdateListing title="migoloo | Update Listing" />}
          />
          <Route
            path='/update-event/:eventId'
            element={<UpdateEventListing title="migoloo | Update Event" />}
          />
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
// export default withSplashScreen(App);  
