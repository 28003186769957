import { useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
// import {
//   getDownloadURL,
//   getStorage,
//   ref,
//   uploadBytesResumable,
// } from 'firebase/storage';
// import { app } from '../firebase';
import {
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  deleteUserFailure,
  deleteUserStart,
  deleteUserSuccess,
  signOutUserStart,
} from "../redux/user/userSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
export default function Profile() {
  const fileRef = useRef(null);
  const { currentUser, loading, error } = useSelector((state) => state.user);
  const [file, setFile] = useState(undefined);
  const [filePerc, setFilePerc] = useState(0);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [formData, setFormData] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [showListingsError, setShowListingsError] = useState(false);
  const [userListings, setUserListings] = useState([]);
  const dispatch = useDispatch();

  // firebase storage
  // allow read;
  // allow write: if
  // request.resource.size < 2 * 1024 * 1024 &&
  // request.resource.contentType.matches('image/.*')

  useEffect(() => {
    if (file) {
      handleFileUpload(file);
    }
  }, [file]);

  //Resize Image
  // const [imageResizedUrl, setImageResizedUrl] = useState(null);

  const resizeImage = (base64Str, maxWidth = 150, maxHeight = 150) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        const MAX_WIDTH = maxWidth;
        const MAX_HEIGHT = maxHeight;
        let width = img.width;
        let height = img.height;
        let shouldResize = false;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
            shouldResize = true;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
            shouldResize = true;
          }
        }
        if (shouldResize) {
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width, height);
          resolve(canvas.toDataURL("image/jpeg", 0.9));
        } else {
          resolve(base64Str);
        }
      };
    });
  };
  //End of Resize Image

  const handleFileUpload = (file) => {
    // const storage = getStorage(app);
    // const fileName = new Date().getTime() + file.name;
    // const storageRef = ref(storage, fileName);
    // const uploadTask = uploadBytesResumable(storageRef, file);

    // uploadTask.on(
    //   'state_changed',
    //   (snapshot) => {
    //     const progress =
    //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     setFilePerc(Math.round(progress));
    //   },
    //   (error) => {
    //     setFileUploadError(true);
    //   },
    //   () => {
    //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
    //       setFormData({ ...formData, avatar: downloadURL })
    //     );
    //   }
    // );

    //Test code
    console.log("aaa", file);
    const reader = new FileReader();
    reader.onload = function (file) {
      console.log("bbb", file.target.result);
      // console.log("bbb+", formData);
      resizeImage(file.target.result, 150, 150).then((result) => {
        // setImageResizedUrl(result);
        // setFormData({ ...formData, avatar: imageResizedUrl });
        setFormData({ ...formData, avatar: result });
        // console.log("ccc", result)
      });
    };
    // reader.readAsDataURL(file.target.files[0]);
    reader.readAsDataURL(file);
    //End Test code
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(updateUserStart());
      const res = await fetch(`/api/user/update/${currentUser._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await res.json();
      if (data.success === false) {
        dispatch(updateUserFailure(data.message));
        return;
      }

      dispatch(updateUserSuccess(data));
      setUpdateSuccess(true);
    } catch (error) {
      dispatch(updateUserFailure(error.message));
    }
  };

  const handleDeleteUser = async () => {
    try {
      dispatch(deleteUserStart());
      const res = await fetch(`/api/user/delete/${currentUser._id}`, {
        method: "DELETE",
      });
      const data = await res.json();
      if (data.success === false) {
        dispatch(deleteUserFailure(data.message));
        return;
      }
      dispatch(deleteUserSuccess(data));
    } catch (error) {
      dispatch(deleteUserFailure(error.message));
    }
  };

  const handleSignOut = async () => {
    let data;
    try {
      dispatch(signOutUserStart());
      const res = await fetch("/api/auth/signout");
      // const data = await res.json();
      data = await res.json();
      if (data.success === false) {
        dispatch(deleteUserFailure(data.message));
        return;
      }
      dispatch(deleteUserSuccess(data));
    } catch (error) {
      dispatch(deleteUserFailure(data.message));
    }
  };

  const handleShowListings = async () => {
    try {
      setShowListingsError(false);
      const res = await fetch(`/api/user/listings/${currentUser._id}`);
      const data = await res.json();
      if (data.success === false) {
        setShowListingsError(true);
        return;
      }

      setUserListings(data);
    } catch (error) {
      setShowListingsError(true);
    }
  };

  const handleListingDelete = async (listingId) => {
    try {
      const res = await fetch(`/api/listing/delete/${listingId}`, {
        method: "DELETE",
      });
      const data = await res.json();
      if (data.success === false) {
        console.log(data.message);
        return;
      }

      setUserListings((prev) =>
        prev.filter((listing) => listing._id !== listingId)
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  // Event Listing
  const [showEventListingsError, setShowEventListingsError] = useState(false);
  const [userEventListings, setUserEventListings] = useState([]);
  const handleShowEventListings = async () => {
    try {
      setShowEventListingsError(false);
      const res = await fetch(`/api/user/events/${currentUser._id}`);
      const data = await res.json();
      if (data.success === false) {
        setShowEventListingsError(true);
        return;
      }

      setUserEventListings(data);
    } catch (error) {
      setShowEventListingsError(true);
    }
  };

  const handleEventListingDelete = async (listingId) => {
    try {
      const res = await fetch(`/api/event/delete/${listingId}`, {
        method: "DELETE",
      });
      const data = await res.json();
      if (data.success === false) {
        console.log(data.message);
        return;
      }

      setUserEventListings((prev) =>
        prev.filter((listing) => listing._id !== listingId)
      );
    } catch (error) {
      console.log(error.message);
    }
  };
  // End of Event Listing

  return (
    <>
      <div className="flex flex-col sm:flex-row px-3 max-w-full mx-auto pt-5">
        <div className="flex-auto w-full sm:w-3/6">
          <div className="p-3 max-w-lg mx-auto">
            <h2 className="text-3xl font-semibold text-center mb-7">Profile</h2>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <input
                onChange={(e) => setFile(e.target.files[0])}
                type="file"
                ref={fileRef}
                hidden
                accept="image/*"
              />
              <img
                onClick={() => fileRef.current.click()}
                src={formData.avatar || currentUser.avatar}
                alt="profile"
                className="rounded-full h-24 w-24 object-cover cursor-pointer self-center mt-2"
              />
              <p className="text-sm self-center">
                {fileUploadError ? (
                  <span className="text-red-700">
                    Error Image upload (image must be less than 2 mb)
                  </span>
                ) : filePerc > 0 && filePerc < 100 ? (
                  <span className="text-slate-700">{`Uploading ${filePerc}%`}</span>
                ) : filePerc === 100 ? (
                  <span className="text-green-700">
                    Image successfully uploaded!
                  </span>
                ) : (
                  ""
                )}
              </p>
              <input
                type="text"
                placeholder="username"
                defaultValue={currentUser.username}
                id="username"
                className="border p-3 rounded-lg"
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="company name"
                defaultValue={currentUser.usercompany}
                id="usercompany"
                className="border p-3 rounded-lg"
                onChange={handleChange}
              />
              <input
                type="email"
                placeholder="email"
                id="email"
                defaultValue={currentUser.email}
                className="border p-3 rounded-lg"
                onChange={handleChange}
                disabled
              />
              <input
                type="password"
                placeholder="password"
                onChange={handleChange}
                id="password"
                className="border p-3 rounded-lg"
              />
              <button
                disabled={loading}
                className="bg-slate-700 text-white rounded-lg p-3 uppercase hover:opacity-95 disabled:opacity-80"
              >
                {loading ? "Loading..." : "Update"}
              </button>
              <Link
                className="bg-green-700 text-white p-3 rounded-lg uppercase text-center hover:opacity-95"
                to={"/create-listing"}
              >
                Create Listing
              </Link>
              {currentUser.evntperm && (
                <Link
                  className="bg-amber-700 text-white p-3 rounded-lg uppercase text-center hover:opacity-95"
                  to={"/create-event"}
                >
                  Create Event
                </Link>
              )}
            </form>
            <div className="flex justify-between mt-5">
              <span
                onClick={handleDeleteUser}
                className="text-red-700 cursor-pointer"
              >
                Delete account
              </span>
              <span
                onClick={handleSignOut}
                className="text-red-700 cursor-pointer"
              >
                Sign out
              </span>
            </div>

            <p className="text-red-700 mt-5">{error ? error : ""}</p>
            <p className="text-green-700 mt-5">
              {updateSuccess ? "User is updated successfully!" : ""}
            </p>
          </div>
        </div>
        <div className="flex-auto w-full sm:w-3/6">
          <div className="py-3 text-center max-w-lg mx-auto">
            <button
              onClick={handleShowListings}
              // className="text-green-700 w-full hover:font-semibold"
              className="bg-slate-700 text-white rounded-lg uppercase hover:opacity-95 p-3"
            >
              Show Listings
            </button>
            <p className="text-red-700 mt-5">
              {showListingsError ? "Error showing listings" : ""}
            </p>

            {userListings && userListings.length > 0 && (
              <div className="flex flex-col gap-4">
                <h2 className="text-center mt-7 text-2xl font-semibold">
                  Your Listings
                </h2>
                {userListings.map((listing) => (
                  <div
                    key={listing._id}
                    className="border rounded-lg p-3 flex justify-between items-center gap-4"
                  >
                    <Link to={`/listing/${listing._id}`}>
                      <img
                        src={listing.imageUrls[0]}
                        alt="listing cover"
                        className="h-16 w-16 object-contain"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="text-slate-700 font-semibold  hover:underline truncate flex-1"
                      to={`/listing/${listing._id}`}
                    >
                      {/* <p>{listing.name}</p> */}
                      <p className="truncate">{listing.address}</p>
                    </Link>

                    <div className="flex flex-col item-center">
                      <button
                        onClick={() => handleListingDelete(listing._id)}
                        className="text-red-700 uppercase"
                      >
                        Delete
                      </button>
                      <Link to={`/update-listing/${listing._id}`}>
                        <button className="text-green-700 uppercase">
                          Edit
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* Show Event Listing */}
        {currentUser.evntperm && (
          <div className="flex-auto w-full sm:w-3/6">
            <div className="py-3 text-center max-w-lg mx-auto">
              <button
                onClick={handleShowEventListings}
                // className="text-green-700 w-full hover:font-semibold"
                className="bg-slate-700 text-white rounded-lg uppercase hover:opacity-95 p-3"
              >
                Show Events
              </button>
              <p className="text-red-700 mt-5">
                {showEventListingsError ? "Error showing listings" : ""}
              </p>

              {userEventListings && userEventListings.length > 0 && (
                <div className="flex flex-col gap-4">
                  <h2 className="text-center mt-7 text-2xl font-semibold">
                    Your Events
                  </h2>
                  {userEventListings.map((listing) => (
                    <div
                      key={listing._id}
                      className="border rounded-lg p-3 flex justify-between items-center gap-4"
                    >
                      <Link to={`/event/${listing._id}`}>
                        <img
                          src={listing.imageUrls[0]}
                          alt="listing cover"
                          className="h-16 w-16 object-contain"
                          loading="lazy"
                        />
                      </Link>
                      <Link
                        className="text-slate-700 font-semibold  hover:underline truncate flex-1"
                        to={`/event/${listing._id}`}
                      >
                        <p>{listing.title}</p>
                        {/* <p className="truncate">{listing.address}</p> */}
                      </Link>

                      <div className="flex flex-col item-center">
                        <button
                          onClick={() => handleEventListingDelete(listing._id)}
                          className="text-red-700 uppercase"
                        >
                          Delete
                        </button>
                        <Link to={`/update-event/${listing._id}`}>
                          <button className="text-green-700 uppercase">
                            Edit
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {/* End of Show Event Listing */}
      </div>

      {/* </div> */}
    </>
  );
}
